.sidebar {
    /* position: absolute;
    top: 10em; */
}

.sidebar-container.full {
    max-width: 14em;
}
.sidebar-container.minified {
    max-width: 5.5em;
}

/* #minified-button {
    position: fixed;
    top: 50%;
    border-radius: 10%;
    background-color: white;
    left: 2em;
} */

.hidden, .badge.hidden {
    display: none;
}

.total-count-element {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
}

.count-title {
    font-weight: bold;
}

/**
 * PALETTE
 * Primary: #004991
 * Secondary: #abb5d8
 * Gray: #3f3f3e
 */

.color-primary {
    color: #004991
}

.color-secondary {
    color: #abb5d8
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.minifiable-panel > .card-body > .card-title {
    margin: 0;
    align-items: center;
}

/**
 * SENTRY BUTTON STYLE
 */

button.sentry-bug-report-icon {
    position: fixed;
    bottom: 1em;
    right: 1em;
    height: 3em;
    width: 3em;
    border: 0.14em solid #ffd500;
    background-color: #ffd500;
    bottom: 1em;
    right: 1em;
    border-radius: 50%;
    outline: 0em;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    color: #555;
    animation-name: shake;
    -webkit-animation-name: shake;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-duration: 5s;
    transition:
        background-color 100ms linear 0s, color 100ms linear 0s,
        border-color 100ms linear 0s, color 100ms linear 0s,
        opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

button.sentry-bug-report-icon:hover {
    background-color: #facf00;
    border-color: #facf00;
    animation-name: shakeAnim;
}

/**
 * ANIMATION FOR SENTRY BUTTON
 */

@keyframes shakeAnim {
  0% {right: 1em 0}
  1% {right: 0.8em}
  2% {right: 1.25em}
  3% {right: 0.75em}
  4% {right: 1.50em}
  5% {right: 0.75em}
  6% {right: 1.1em}
  7% {right: 1em 0}
}

@keyframes shake {
    0% {right: 1em 0}
    1% {right: 0.8em}
    2% {right: 1.25em}
    3% {right: 0.75em}
    4% {right: 1.50em}
    5% {right: 0.75em}
    6% {right: 1.1em}
    7% {right: 1em 0}
}

.dashboard-table-container {
    overflow-x: scroll;
}

.caret-container {
    display: inline-block;
    margin-left: 0.5em;
}

.sort-icon {
    color: #a4adbf;
}

.sort-icon.sort-selected {
    color: #004991;
}
.show-token-information {
    padding: 0!important;
}

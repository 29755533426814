.metadata-box {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    flex-wrap: wrap;

}

.metadata-container {
    padding: 0.5em;
    margin-top: 1em;
    margin-right: 0.25em;
    margin-left: 0.25em;
}

.metadata-title {
    font-weight: bold;
}
